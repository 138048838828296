<template>
  <div class="_container">
    <nav class="navbar fixed-top navbar-light">
      <a href="">
        <img
          class="imgtop"
          src="../../../../public/resources/images/gb-sisi/logo-at-putih.png"
          alt=""
          style="width: 150px; margin-top: 0%; "
        />
      </a>
    </nav>
    <div class="_card">
      <a-card class="card">
        <img
          class="center"
          src="../../../../public/resources/images/gb-sisi/logo-at.png"
          alt=""
          style=" width: 250px;"
        />
        <p>
          Silahkan masukan ID Toko Anda untuk melanjutkan regitrasi ! <br />
          Sudah pernah Registrasi ?
        </p>
        <router-link to="/auth/login">
          <p class="text-danger ml-4" style="margin-top: 10px;">Log In</p>
        </router-link>
        <a-form
          :model="loginForm"
          :rules="rules"
          layout="vertical"
          class="mb-4"
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item name="email" class="email">
            <a-input v-model:value="loginForm.email" placeholder="ID Toko" />
          </a-form-item>
          <a-button type="danger" html-type="submit" class="tombol" :loading="loading">
            <strong>Sign Up</strong>
          </a-button>
          <br />
        </a-form>
      </a-card>
    </div>
    <div class="footer">
      <div class="d-flex justify-content-between mt-3">
        <div>
          <a style="font-size: 17px; color: white;" class="ml-2" href="">Faq</a>
          <i style="font-size: 17px; color: white;" class="fa fa-chevron-circle-right ml-2"></i>
        </div>
        <div class="txt">
          2021 PT Sinergi Informatika Semen Indonesia, <br />
          anak usaha dari PT Semen Indonesia TBK. <br />
          All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'VbLogin',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = values => {
      store.dispatch('user/LOGIN', { payload: values })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
    }
  },
}
</script>
<style scoped>
.navbar {
  background-color: #630700;
  height: 3.5rem;
  padding: 2.3rem 0;
  margin-bottom: 0.3rem;
  /* position: relative; */
}

.imgtop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.card {
  position: relative;
  margin: 0 auto;
  width: 26rem;
}

.card img {
  /* border: 1px solid red; */
  margin: 0 auto;
  align-items: center;
  display: block;
  width: 100%;
}

p {
  color: black;
  text-align: center;
  margin-top: 10%;
}

.label {
  text-align: left;
}

.tombol {
  width: 90%;
  display: block;
  margin: 0 auto;
}

._container {
  /* background: orange; */
  width: 100%;
  position: relative;
}

._card {
  /* border: 1px solid blue; */
  min-height: 100vh;
  padding-bottom: 6.5rem;
  padding-top: 5.8rem;
  position: relative;
}
.footer {
  background-color: #630700;
  height: 6.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 0.5rem;
}

.txt {
  color: white;
}

@media only screen and (max-width: 300px) {
  .card {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  .txt {
    font-size: 0.9rem;
  }
}
</style>
